import { Viewer } from "../utils/webglViewer.js";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { Flip } from "gsap/all";
gsap.registerPlugin(ScrollTrigger, Flip);

export default () => {
    const webGlFile = "/dist/3d/model/IPZ_webgl_08-fix.glb";
    let webglViewer;
    let minWebglViewer;
    return {
        isModelLoaded: false,
        showWebGL: false,
        videosPlayer: [],
        webglOuterState: false,
        isWebGlSticked: false,
        isFullScreen: false,
        currentBuilding: null,
        buildings: [
            {
                key: "space-hub",
                name: "Space Hub",
                position: {
                    x: -293.54025861967585,
                    y: -22.917694187462647,
                    z: -35.05239468508127,
                },
                camera: {
                    x: -387.3227660281039,
                    y: 14.86058914784341,
                    z: -31.157162593912055,
                },
            },
            {
                key: "buro-zuri",
                name: "Büro Züri",
                position: {
                    x: -264.02235903376845,
                    y: -25.339772634068385,
                    z: -79.74059012650193,
                },
                camera: {
                    x: -340.8249765391031,
                    y: 14.463197707135244,
                    z: -79.0835531896428,
                },
            },
            {
                key: "hq-ipz",
                name: "HQ IPZ",
                position: {
                    x: -251.82998017468094,
                    y: -21.783130160809772,
                    z: -116.1130667969741,
                },
                camera: {
                    x: -318.1743010836044,
                    y: 16.727487677667707,
                    z: -92.96852222187718,
                },
            },
            {
                key: "startbahn-29",
                name: "Startbahn 29",
                position: {
                    x: -254,
                    y: -10,
                    z: -127,
                },
                camera: {
                    x: -317.07654009113963,
                    y: 24.337648137708058,
                    z: -96.11615585922594,
                },
            },
            {
                key: "angst-pfister",
                name: "Angst & Pfister",
                position: {
                    x: -221.11713721203728,
                    y: -23.14023640379135,
                    z: -141.0881229331523,
                },
                camera: {
                    x: -269.26225403217046,
                    y: 1.4174857734122597,
                    z: -111.23638687144344,
                },
            },
            {
                key: "cellsius",
                name: "Cellsius",
                position: {
                    x: -201.1571718775386,
                    y: -23.44770638092194,
                    z: -156.38727979794305,
                },
                camera: {
                    x: -214.14171165677888,
                    y: -3.1404496496055927,
                    z: -82.96672165000346,
                },
            },
            {
                key: "aris",
                name: "ARIS",
                position: {
                    x: -186.31208571694046,
                    y: -26.61112619902853,
                    z: -169.92755941933052,
                },
                camera: {
                    x: -161.66485799769984,
                    y: -18.797401268834143,
                    z: -132.20040535775456,
                },
            },
            {
                key: "amz",
                name: "AMZ",
                position: {
                    x: -161.28138070186375,
                    y: -27.58239729419915,
                    z: -188.5027426075011,
                },
                camera: {
                    x: -133.7023925260665,
                    y: -19.085495551116743,
                    z: -150.06890879355922,
                },
            },
            {
                key: "swissloop",
                name: "Swissloop",
                position: {
                    x: -137.1837923081472,
                    y: -23.00738487790716,
                    z: -206.73312881047033,
                },
                camera: {
                    x: -114.52252133440848,
                    y: -18.597665334263183,
                    z: -171.38511471019115,
                },
            },
            {
                key: "center-for-immersive-wave",
                name: "Center for Immersive Wave Experimentation",
                position: {
                    x: -99.64352592846123,
                    y: -24.277237251409595,
                    z: -232.48061212219443,
                },
                camera: {
                    x: -93.6522515340214,
                    y: -17.649808517782937,
                    z: -194.53346427156634,
                },
            },
            {
                key: "eth-hangar",
                name: "ETH Hangar",
                position: {
                    x: -170.35699948503904,
                    y: -20.750397289667124,
                    z: -212.7900933289083,
                },
                camera: {
                    x: -36.699394571241555,
                    y: 36.27723240952052,
                    z: -120.9959890353368,
                },
            },
            {
                key: "event-hangar",
                name: "Event Hangar",
                position: {
                    x: -16,
                    y: -7,
                    z: -252,
                },
                camera: {
                    x: -11.235056800266506,
                    y: 19.865680370384048,
                    z: -212.95392081489436,
                },
            },
        ],
        init() {
            const hash = location.hash ? queryString.parse(location.hash) : {};

            this.videosPlayer = Array.from(
                this.$el.querySelectorAll("video"),
            ).map((video) => {
                // add on play end watcher
                video.addEventListener("ended", (e) => {
                    this.showWebGL = true;
                });
            });

            this.$watch("activeLink", () => {
                this.navigateToBuilding(this.activeLink.building);
            });

            // FOR DEV PURPOSES to not wait until video ends
            this.showWebGL = true;
            // setTimeout(() => {
            //     this.showWebGL = true;
            // }, 5 * 1000);

            this.options = {
                kiosk: Boolean(hash.kiosk),
                model: hash.model || "",
                preset: hash.preset || "",
                cameraPosition: hash.cameraPosition
                    ? hash.cameraPosition.split(",").map(Number)
                    : [-301, 83, -343],
                objectPosition: hash.cameraLookAt
                    ? hash.cameraLookAt.split(",").map(Number)
                    : [-125, -23, -236],
            };

            this.setWebGlView();
            this.setScrollMoveCamera();
        },

        navigateToBuilding(buildingName) {
            const object = this.buildings.find((v) => v.key === buildingName);

            this.currentBuilding = object;

            this.playToBuildingAnimation(object);
        },

        goToNextBuilding() {
            const currentBuildingIndex =
                this.buildings.findIndex(
                    (v) => v.key === this.currentBuilding.key,
                ) || 0;
            const nextBuildingIndex =
                currentBuildingIndex + 1 >= this.buildings.length
                    ? 0
                    : currentBuildingIndex + 1;

            const nextBuilding = this.buildings[nextBuildingIndex];

            this.currentBuilding = nextBuilding;
            this.playToBuildingAnimation(nextBuilding);
        },

        goToPrevBuilding() {
            const currentBuildingIndex =
                this.buildings.findIndex(
                    (v) => v.key === this.currentBuilding.key,
                ) || 0;
            const prevBuildingIndex =
                currentBuildingIndex - 1 < 0
                    ? this.buildings.length - 1
                    : currentBuildingIndex - 1;
            const prevBuilding = this.buildings[prevBuildingIndex];

            this.currentBuilding = prevBuilding;
            this.playToBuildingAnimation(prevBuilding);
        },

        showFullScreen() {
            if (!minWebglViewer) return;
            this.isFullScreen = true;
            minWebglViewer.goToFullScreen();
        },

        closeFullWindow() {
            if (!minWebglViewer) return;
            this.isFullScreen = false;
            if (this.currentBuilding.kay !== this.activeLink.building) {
                this.navigateToBuilding(this.activeLink.building);
            }
            minWebglViewer.goToDefaultScreen();
        },

        playToBuildingAnimation(building) {
            if (!building || !minWebglViewer) return;

            const { x, y, z } = building.position;
            const {
                x: cameraX,
                y: cameraY,
                z: cameraZ,
            } = building.camera || building.position;

            const timeline = gsap.timeline();

            if (building.position) {
                timeline.to(minWebglViewer.controls.target, {
                    x,
                    y,
                    z,
                    duration: 2,
                    ease: "power1.inOut",
                });
            }

            if (building.camera) {
                timeline.to(
                    minWebglViewer.defaultCamera.position,
                    {
                        x: cameraX,
                        y: cameraY,
                        z: cameraZ,
                        duration: 2,
                        ease: "power1.inOut",
                    },
                    "<0%",
                );
            }
        },

        setScrollMoveCamera() {
            const timeline = gsap.timeline({
                scrollTrigger: {
                    markers: false,
                    pin: this.$refs.webglOuter,
                    trigger: this.$el,
                    pinSpacer: true,
                    scrub: 2,
                    start: "top top",
                    end: "bottom center+=20%",
                    onEnter: () => {},
                },
            });

            timeline.fromTo(
                this.$refs.webglOuter,
                {
                    clipPath: "polygon(0px 0px,0% 0px,-50% 100%,0 100%)",
                },
                {
                    clipPath: "polygon(0px 0px,150% 0px,100% 100%,0 100%)",
                },
            );

            timeline.fromTo(
                webglViewer.defaultCamera.position,
                {
                    x: -301,
                    y: 83,
                    z: -343,
                },
                {
                    x: -301,
                    y: 83,
                    z: -343,
                    duration: 1,
                    ease: "power1.inOut",
                },

                // {
                //     x: -228.58520309004084,
                //     y: 104.17312975865725,
                //     z: -354.4832082322805,
                //     // x: 187.61631658424932,
                //     // y: 99.46677004187111,
                //     // z: -119.10413692151363,
                //     duration: 1,
                //     ease: "power1.inOut",
                // },
            );

            // timeline.fromTo(
            //     webglViewer.controls.target,
            //     {
            //         x: -125,
            //         y: -23,
            //         z: -236,
            //     },
            //     {
            //         x: -14.651621672021362,
            //         y: -89.87004173437984,
            //         z: -219.25901130515655,
            //         duration: 1,
            //         ease: "power1.inOut",
            //     },
            //     "<0%",
            // );
        },

        setWebGlView() {
            if (webglViewer) {
                webglViewer.clear();
            }

            webglViewer = new Viewer(this.$refs.webgl, {
                ...this.options,
            });

            minWebglViewer = new Viewer(this.$refs.webgl2, this.options);

            webglViewer
                .load(webGlFile)
                .catch((e) => this.onError(e))
                .then((gltf) => {
                    this.isModelLoaded = true;
                    // webglViewer.setCameraPosition(-528, 101, -330);
                });

            minWebglViewer
                .load(webGlFile)
                .catch((e) => this.onError(e))
                .then((gltf) => {
                    this.isModelLoaded = true;
                    // webglViewer.setCameraPosition(-528, 101, -330);
                });
        },

        onError(error) {
            console.error(error);
        },

        destroy() {},

        get goToPageUrl() {
            return this.activeLink ? this.activeLink.url : null;
        },

        get goToPageTitle() {
            return this.isFullScreen
                ? this.currentBuilding.name
                : this.activeLink
                  ? this.activeLink.title
                  : null;
        },
    };
};
