import {
    AmbientLight,
    AnimationMixer,
    AxesHelper,
    Box3,
    Cache,
    Color,
    DirectionalLight,
    GridHelper,
    HemisphereLight,
    LoaderUtils,
    SphereGeometry,
    MeshBasicMaterial,
    Mesh,
    LoadingManager,
    PMREMGenerator,
    PerspectiveCamera,
    PointsMaterial,
    REVISION,
    Scene,
    SkeletonHelper,
    Vector3,
    WebGLRenderer,
    LinearToneMapping,
    ACESFilmicToneMapping,
} from "three";
import Stats from "three/addons/libs/stats.module.js";
import { GLTFLoader } from "three/addons/loaders/GLTFLoader.js";
import { KTX2Loader } from "three/addons/loaders/KTX2Loader.js";
import { DRACOLoader } from "three/addons/loaders/DRACOLoader.js";
import { MeshoptDecoder } from "three/addons/libs/meshopt_decoder.module.js";
import { OrbitControls } from "three/addons/controls/OrbitControls.js";
import { EXRLoader } from "three/addons/loaders/EXRLoader.js";
import { RoomEnvironment } from "three/addons/environments/RoomEnvironment.js";
import { GUI } from "three/addons/libs/lil-gui.module.min.js";
import { DragControls } from "three/examples/jsm/controls/DragControls.js";

const environments = [
    {
        id: "",
        name: "None",
        path: null,
    },
    {
        id: "neutral", // THREE.RoomEnvironment
        name: "Neutral",
        path: null,
    },
];

const DEFAULT_CAMERA = "[default]";

const MANAGER = new LoadingManager();
const DRACO_LOADER = new DRACOLoader(MANAGER).setDecoderPath(
    "/dist/3d/model/draco/gltf/",
);
const KTX2_LOADER = new KTX2Loader(MANAGER).setTranscoderPath(
    "/dist/3d/model/draco/basis/",
);

const Preset = { ASSET_GENERATOR: "assetgenerator" };

Cache.enabled = true;

export class Viewer {
    constructor(el, options) {
        this.el = el;
        this.options = options;

        this.lights = [];
        this.content = null;
        this.mixer = null;
        this.clips = [];
        this.gui = null;

        this.state = {
            environment:
                options.preset === Preset.ASSET_GENERATOR
                    ? environments.find((e) => e.id === "footprint-court").name
                    : environments[1].name,
            background: false,
            playbackSpeed: 1.0,
            actionStates: {},
            camera: DEFAULT_CAMERA,
            wireframe: false,
            skeleton: false,
            grid: false,
            autoRotate: false,

            // Lights
            punctualLights: true,
            exposure: 0.0,
            toneMapping: ACESFilmicToneMapping,
            ambientIntensity: 0.3,
            ambientColor: "#FFFFFF",
            directIntensity: 0 * Math.PI, // TODO(#116)
            directColor: "#FFFFFF",
            bgColor: "#EBECE6",

            pointSize: 1.0,
            scene: {
                initialWidth: el.clientWidth,
                initialHeight: el.clientHeight,
                fullWidth: window.innerWidth,
                fullHeight: window.innerHeight,
            },
            animation: {
                duration: 700,
            },
        };

        this.prevTime = 0;

        this.stats = new Stats();
        this.stats.dom.height = "48px";
        [].forEach.call(
            this.stats.dom.children,
            (child) => (child.style.display = ""),
        );

        this.backgroundColor = new Color(this.state.bgColor);

        this.scene = new Scene();
        this.scene.background = this.backgroundColor;

        // const geometry1 = new SphereGeometry(10, 32, 16);
        // const material1 = new MeshBasicMaterial({ color: 0xffff00 });
        // const sphere = new Mesh(geometry1, material1);
        // this.scene.add(sphere);
        // sphere.position.set(-60, -10, -150);

        // const objects = [sphere];

        const fov =
            options.preset === Preset.ASSET_GENERATOR
                ? (0.8 * 180) / Math.PI
                : 60;

        const aspect = el.clientWidth / el.clientHeight;
        this.defaultCamera = new PerspectiveCamera(fov, aspect, 0.01, 1000);
        this.activeCamera = this.defaultCamera;
        this.scene.add(this.defaultCamera);

        this.renderer = window.renderer = new WebGLRenderer({
            antialias: true,
        });
        this.renderer.setClearColor(0xcccccc);
        this.renderer.setPixelRatio(window.devicePixelRatio);
        this.renderer.setSize(el.clientWidth, el.clientHeight);

        // this.renderer.domElement.addEventListener("click", () => {
        //     this.goToFullScreen();
        // });

        this.pmremGenerator = new PMREMGenerator(this.renderer);
        this.pmremGenerator.compileEquirectangularShader();

        this.neutralEnvironment = this.pmremGenerator.fromScene(
            new RoomEnvironment(),
        ).texture;

        this.controls = new OrbitControls(
            this.defaultCamera,
            this.renderer.domElement,
        );

        this.controls.screenSpacePanning = true;

        // this.controls.addEventListener("change", () => {
        //     console.log(this.activeCamera.position);
        //     console.log(
        //         `{x:${this.activeCamera.position.x}, y:${this.activeCamera.position.y}, z:${this.activeCamera.position.z}}`,
        //     );

        //     console.log("controls look at---");
        //     console.log(this.controls.target);
        //     console.log("---controls look at");
        // });

        this.el.appendChild(this.renderer.domElement);

        // const dragControls = new DragControls(
        //     objects,
        //     this.defaultCamera,
        //     this.renderer.domElement,
        // );

        // dragControls.addEventListener("dragstart", (event) => {
        //     // Optional: Disable other controls like OrbitControls if you have them
        //     // orbitControls.enabled = false;
        //     this.controls.enabled = false;
        // });

        // dragControls.addEventListener("dragend", (event) => {
        //     // Optional: Re-enable other controls
        //     // orbitControls.enabled = true;
        //     this.controls.enabled = true;

        //     // Get the new position of the sphere
        //     const position = event.object.position;
        //     console.log("Drag ended. New position:", position);
        // });

        this.cameraCtrl = null;
        this.cameraFolder = null;
        this.animFolder = null;
        this.animCtrls = [];
        this.morphFolder = null;
        this.morphCtrls = [];
        this.skeletonHelpers = [];
        this.gridHelper = null;
        this.axesHelper = null;

        this.addAxesHelper();

        if (options.showGui) this.addGUI();
        if (options.kiosk) this.gui.close();

        this.animate = this.animate.bind(this);
        requestAnimationFrame(this.animate);
        window.addEventListener("resize", this.resize.bind(this), false);
    }

    animate(time) {
        requestAnimationFrame(this.animate);

        this.toFullScreenAnimation();
        this.toDefaultScreenAnimation();

        const dt = (time - this.prevTime) / 1000;

        this.controls.update();
        this.stats.update();
        this.mixer && this.mixer.update(dt);
        this.render();

        this.prevTime = time;
    }

    render() {
        this.renderer.render(this.scene, this.activeCamera);

        if (this.state.grid) {
            this.axesCamera.position.copy(this.defaultCamera.position);
            this.axesCamera.lookAt(this.axesScene.position);
            this.axesRenderer.render(this.axesScene, this.axesCamera);
        }
    }

    resize() {
        setTimeout(() => {
            const { clientHeight, clientWidth } = this.el.parentElement;

            this.state.scene = {
                initialWidth: this.el.clientWidth,
                initialHeight: this.el.clientHeight,
                fullWidth: window.innerWidth,
                fullHeight: window.innerHeight,
            };

            this.defaultCamera.aspect = clientWidth / clientHeight;
            this.defaultCamera.updateProjectionMatrix();
            this.renderer.setSize(clientWidth, clientHeight);

            this.axesCamera.aspect =
                this.axesDiv.clientWidth / this.axesDiv.clientHeight;
            this.axesCamera.updateProjectionMatrix();
            this.axesRenderer.setSize(
                this.axesDiv.clientWidth,
                this.axesDiv.clientHeight,
            );
        }, 100);
    }

    load(filePath) {
        // const baseURL = LoaderUtils.extractUrlBase(url);

        // Load.
        return new Promise((resolve, reject) => {
            //     // Intercept and override relative URLs.
            //     MANAGER.setURLModifier((url, path) => {
            //         // URIs in a glTF file may be escaped, or not. Assume that assetMap is
            //         // from an un-escaped source, and decode all URIs before lookups.
            //         // See: https://github.com/donmccurdy/three-gltf-viewer/issues/146
            //         const normalizedURL =
            //             rootPath +
            //             decodeURI(url)
            //                 .replace(baseURL, "")
            //                 .replace(/^(\.?\/)/, "");

            //         if (assetMap.has(normalizedURL)) {
            //             const blob = assetMap.get(normalizedURL);
            //             const blobURL = URL.createObjectURL(blob);
            //             blobURLs.push(blobURL);
            //             return blobURL;
            //         }

            //         return (path || "") + url;
            //     });

            const loader = new GLTFLoader()
                .setCrossOrigin("anonymous")
                .setDRACOLoader(DRACO_LOADER)
                .setKTX2Loader(KTX2_LOADER.detectSupport(this.renderer))
                .setMeshoptDecoder(MeshoptDecoder);

            const blobURLs = [];

            loader.load(
                filePath,
                (gltf) => {
                    // window.VIEWER.json = gltf;

                    const scene = gltf.scene || gltf.scenes[0];
                    const clips = gltf.animations || [];

                    if (!scene) {
                        // Valid, but not supported by this viewer.
                        throw new Error(
                            "This model contains no scene, and cannot be viewed here. However," +
                                " it may contain individual 3D resources.",
                        );
                    }

                    this.setContent(scene, clips);

                    blobURLs.forEach(URL.revokeObjectURL);

                    // See: https://github.com/google/draco/issues/349
                    // DRACOLoader.releaseDecoderModule();

                    resolve(gltf);
                },
                undefined,
                reject,
            );
        });
    }

    /**
     * @param {THREE.Object3D} object
     * @param {Array<THREE.AnimationClip} clips
     */
    setContent(object, clips) {
        this.clear();

        const objToPositionCalc = object.children[1];

        object.updateMatrixWorld(); // donmccurdy/three-gltf-viewer#330

        const box = new Box3().setFromObject(objToPositionCalc);
        const size = box.getSize(new Vector3()).length();
        const center = box.getCenter(new Vector3());

        this.controls.reset();

        object.position.x -= center.x;
        object.position.y -= center.y;
        object.position.z -= center.z;

        this.controls.maxDistance = size * 10;

        this.defaultCamera.near = size / 100;
        this.defaultCamera.far = size * 100;
        this.defaultCamera.updateProjectionMatrix();

        if (this.options.cameraPosition) {
            this.defaultCamera.position.fromArray(this.options.cameraPosition);
            this.defaultCamera.lookAt(new Vector3());
        } else {
            this.defaultCamera.position.copy(center);
            this.defaultCamera.position.x += size / 2.0;
            this.defaultCamera.position.y += size / 5.0;
            this.defaultCamera.position.z += size / 2.0;
            this.defaultCamera.lookAt(center);
        }

        if (this.options.objectPosition) {
            this.controls.target.fromArray(this.options.objectPosition);
            this.controls.update();
        }

        this.setCamera(DEFAULT_CAMERA);

        this.axesCamera.position.copy(this.defaultCamera.position);
        this.axesCamera.lookAt(this.axesScene.position);
        this.axesCamera.near = size / 100;
        this.axesCamera.far = size * 100;
        this.axesCamera.updateProjectionMatrix();
        this.axesCorner.scale.set(size, size, size);

        this.controls.saveState();

        this.scene.add(object);
        this.content = object;

        this.state.punctualLights = false;

        this.content.traverse((node) => {
            if (node.isLight) {
                this.state.punctualLights = false;
            }
        });

        this.setClips(clips);

        this.updateLights();

        if (this.options.showGui) {
            this.updateGUI();
        }
        this.updateEnvironment();
        this.updateDisplay();

        // this.printGraph(this.content);
    }

    printGraph(node) {
        console.group(" <" + node.type + "> " + node.name);
        node.children.forEach((child) => this.printGraph(child));
        console.groupEnd();
    }

    /**
     * @param {Array<THREE.AnimationClip} clips
     */
    setClips(clips) {
        if (this.mixer) {
            this.mixer.stopAllAction();
            this.mixer.uncacheRoot(this.mixer.getRoot());
            this.mixer = null;
        }

        this.clips = clips;
        if (!clips.length) return;

        this.mixer = new AnimationMixer(this.content);
    }

    playAllClips() {
        this.clips.forEach((clip) => {
            this.mixer.clipAction(clip).reset().play();
            this.state.actionStates[clip.name] = true;
        });
    }

    /**
     * @param {string} name
     */
    setCamera(name) {
        if (name === DEFAULT_CAMERA) {
            this.controls.enabled = true;
            this.activeCamera = this.defaultCamera;
        } else {
            this.controls.enabled = false;
            this.content.traverse((node) => {
                if (node.isCamera && node.name === name) {
                    this.activeCamera = node;
                }
            });
        }
    }

    setCameraPosition(x, y, z) {
        this.defaultCamera.position.x = x;
        this.defaultCamera.position.y = y;
        this.defaultCamera.position.z = z;
    }

    updateLights() {
        const state = this.state;
        const lights = this.lights;

        if (state.punctualLights && !lights.length) {
            this.addLights();
        } else if (!state.punctualLights && lights.length) {
            this.removeLights();
        }

        this.renderer.toneMapping = Number(state.toneMapping);
        this.renderer.toneMappingExposure = Math.pow(2, state.exposure);

        if (lights.length === 2) {
            lights[0].intensity = state.ambientIntensity;
            lights[0].color.set(state.ambientColor);
            lights[1].intensity = state.directIntensity;
            lights[1].color.set(state.directColor);
        }
    }

    addLights() {
        const state = this.state;

        if (this.options.preset === Preset.ASSET_GENERATOR) {
            const hemiLight = new HemisphereLight();
            hemiLight.name = "hemi_light";
            this.scene.add(hemiLight);
            this.lights.push(hemiLight);
            return;
        }

        const light1 = new AmbientLight(
            state.ambientColor,
            state.ambientIntensity,
        );
        light1.name = "ambient_light";
        this.defaultCamera.add(light1);

        const light2 = new DirectionalLight(
            state.directColor,
            state.directIntensity,
        );
        light2.position.set(0.5, 0, 0.866); // ~60º
        light2.name = "main_light";
        this.defaultCamera.add(light2);

        this.lights.push(light1, light2);
    }

    removeLights() {
        this.lights.forEach((light) => light.parent.remove(light));
        this.lights.length = 0;
    }

    updateEnvironment() {
        const environment = environments.filter(
            (entry) => entry.name === this.state.environment,
        )[0];

        this.getCubeMapTexture(environment).then(({ envMap }) => {
            this.scene.environment = envMap;
            this.scene.background = this.state.background
                ? envMap
                : this.backgroundColor;
        });
    }

    getCubeMapTexture(environment) {
        const { id, path } = environment;

        // neutral (THREE.RoomEnvironment)
        if (id === "neutral") {
            return Promise.resolve({ envMap: this.neutralEnvironment });
        }

        // none
        if (id === "") {
            return Promise.resolve({ envMap: null });
        }

        return new Promise((resolve, reject) => {
            new EXRLoader().load(
                path,
                (texture) => {
                    const envMap =
                        this.pmremGenerator.fromEquirectangular(
                            texture,
                        ).texture;
                    this.pmremGenerator.dispose();

                    resolve({ envMap });
                },
                undefined,
                reject,
            );
        });
    }

    updateDisplay() {
        if (this.skeletonHelpers.length) {
            this.skeletonHelpers.forEach((helper) => this.scene.remove(helper));
        }

        traverseMaterials(this.content, (material) => {
            material.wireframe = this.state.wireframe;

            if (material instanceof PointsMaterial) {
                material.size = this.state.pointSize;
            }
        });

        this.content.traverse((node) => {
            if (node.geometry && node.skeleton && this.state.skeleton) {
                const helper = new SkeletonHelper(
                    node.skeleton.bones[0].parent,
                );
                helper.material.linewidth = 3;
                this.scene.add(helper);
                this.skeletonHelpers.push(helper);
            }
        });

        if (this.state.grid !== Boolean(this.gridHelper)) {
            if (this.state.grid) {
                this.gridHelper = new GridHelper(1000, 50);
                this.axesHelper = new AxesHelper(500);
                this.axesHelper.renderOrder = 999;
                this.axesHelper.onBeforeRender = (renderer) =>
                    renderer.clearDepth();
                this.scene.add(this.gridHelper);
                this.scene.add(this.axesHelper);
            } else {
                this.scene.remove(this.gridHelper);
                this.scene.remove(this.axesHelper);
                this.gridHelper = null;
                this.axesHelper = null;
                this.axesRenderer.clear();
            }
        }

        this.controls.autoRotate = this.state.autoRotate;
    }

    updateBackground() {
        this.backgroundColor.set(this.state.bgColor);
    }

    easeOutQuad(t) {
        // return t * (2 - t);
        return --t * t * t + 1;
    }

    goToFullScreen() {
        if (this.isTransitioningFoFullScreen || this.isInFullScreen) return;
        this.isTransitioningFoFullScreen = true;
        this.isInFullScreen = true;
        this.startTime = performance.now();
    }

    goToDefaultScreen() {
        if (this.isTransitioningToDefaultScreen) return;
        this.isTransitioningToDefaultScreen = true;
        this.isInFullScreen = false;
        this.startTime = performance.now();
    }

    toDefaultScreenAnimation() {
        if (this.isTransitioningToDefaultScreen) {
            const elapsedTime = performance.now() - this.startTime;
            let t = Math.min(elapsedTime / this.state.animation.duration, 1);
            t = this.easeOutQuad(t);

            const currentWidth =
                this.state.scene.fullWidth +
                (this.state.scene.initialWidth - this.state.scene.fullWidth) *
                    t;

            const currentHeight =
                this.state.scene.fullHeight +
                (this.state.scene.initialHeight - this.state.scene.fullHeight) *
                    t;

            this.renderer.setSize(currentWidth, currentHeight);

            const currentLeft = -20 + 20 * t;
            const currentBottom = -40 + 40 * t;

            this.el.style.width = `${currentWidth}px`;
            this.el.style.height = `${currentHeight}px`;
            this.el.style.left = `${currentLeft}px`;
            this.el.style.bottom = `${currentBottom}px`;

            this.defaultCamera.aspect = currentWidth / currentHeight;
            this.defaultCamera.updateProjectionMatrix();

            if (t >= 1) {
                this.isTransitioningToDefaultScreen = false;
            }
        }
    }

    toFullScreenAnimation() {
        if (this.isTransitioningFoFullScreen) {
            const elapsedTime = performance.now() - this.startTime;
            let t = Math.min(elapsedTime / this.state.animation.duration, 1);
            t = this.easeOutQuad(t);

            const currentWidth =
                this.state.scene.initialWidth +
                (this.state.scene.fullWidth - this.state.scene.initialWidth) *
                    t;
            const currentHeight =
                this.state.scene.initialHeight +
                (this.state.scene.fullHeight - this.state.scene.initialHeight) *
                    t;

            this.renderer.setSize(currentWidth, currentHeight);

            const currentLeft = 0 + -20 * t;
            const currentBottom = 20 + -40 * t;

            this.el.style.width = `${currentWidth}px`;
            this.el.style.height = `${currentHeight}px`;
            this.el.style.left = `${currentLeft}px`;
            this.el.style.bottom = `${currentBottom}px`;

            this.defaultCamera.aspect = currentWidth / currentHeight;
            this.defaultCamera.updateProjectionMatrix();

            if (t >= 1) {
                this.isTransitioningFoFullScreen = false;
                // initialWidth = targetWidth;
                // initialHeight = targetHeight;
                this.el.style.width = `100vw`;
                this.el.style.height = `100vh`;
            }
        }
    }

    /**
     * Adds AxesHelper.
     *
     * See: https://stackoverflow.com/q/16226693/1314762
     */
    addAxesHelper() {
        this.axesDiv = document.createElement("div");
        this.el.appendChild(this.axesDiv);
        this.axesDiv.classList.add("axes");

        const { clientWidth, clientHeight } = this.axesDiv;

        this.axesScene = new Scene();
        this.axesCamera = new PerspectiveCamera(
            50,
            clientWidth / clientHeight,
            0.1,
            10,
        );
        this.axesScene.add(this.axesCamera);

        this.axesRenderer = new WebGLRenderer({ alpha: true });
        this.axesRenderer.setPixelRatio(window.devicePixelRatio);
        this.axesRenderer.setSize(
            this.axesDiv.clientWidth,
            this.axesDiv.clientHeight,
        );

        this.axesCamera.up = this.defaultCamera.up;

        this.axesCorner = new AxesHelper(5);
        this.axesScene.add(this.axesCorner);
        this.axesDiv.appendChild(this.axesRenderer.domElement);
    }

    addGUI() {
        const gui = (this.gui = new GUI({
            autoPlace: false,
            width: 260,
            hideable: true,
        }));

        // Display controls.
        const dispFolder = gui.addFolder("Display");
        const envBackgroundCtrl = dispFolder.add(this.state, "background");
        envBackgroundCtrl.onChange(() => this.updateEnvironment());
        const autoRotateCtrl = dispFolder.add(this.state, "autoRotate");
        autoRotateCtrl.onChange(() => this.updateDisplay());
        const wireframeCtrl = dispFolder.add(this.state, "wireframe");
        wireframeCtrl.onChange(() => this.updateDisplay());
        const skeletonCtrl = dispFolder.add(this.state, "skeleton");
        skeletonCtrl.onChange(() => this.updateDisplay());
        const gridCtrl = dispFolder.add(this.state, "grid");
        gridCtrl.onChange(() => this.updateDisplay());
        dispFolder.add(this.controls, "screenSpacePanning");
        const pointSizeCtrl = dispFolder.add(this.state, "pointSize", 1, 16);
        pointSizeCtrl.onChange(() => this.updateDisplay());
        const bgColorCtrl = dispFolder.addColor(this.state, "bgColor");
        bgColorCtrl.onChange(() => this.updateBackground());

        // Lighting controls.
        const lightFolder = gui.addFolder("Lighting");
        const envMapCtrl = lightFolder.add(
            this.state,
            "environment",
            environments.map((env) => env.name),
        );
        envMapCtrl.onChange(() => this.updateEnvironment());
        [
            lightFolder.add(this.state, "toneMapping", {
                Linear: LinearToneMapping,
                "ACES Filmic": ACESFilmicToneMapping,
            }),
            lightFolder.add(this.state, "exposure", -10, 10, 0.01),
            lightFolder.add(this.state, "punctualLights").listen(),
            lightFolder.add(this.state, "ambientIntensity", 0, 2),
            lightFolder.addColor(this.state, "ambientColor"),
            lightFolder.add(this.state, "directIntensity", 0, 4), // TODO(#116)
            lightFolder.addColor(this.state, "directColor"),
        ].forEach((ctrl) => ctrl.onChange(() => this.updateLights()));

        // Animation controls.
        this.animFolder = gui.addFolder("Animation");
        this.animFolder.domElement.style.display = "none";
        const playbackSpeedCtrl = this.animFolder.add(
            this.state,
            "playbackSpeed",
            0,
            1,
        );
        playbackSpeedCtrl.onChange((speed) => {
            if (this.mixer) this.mixer.timeScale = speed;
        });
        this.animFolder.add({ playAll: () => this.playAllClips() }, "playAll");

        // Morph target controls.
        this.morphFolder = gui.addFolder("Morph Targets");
        this.morphFolder.domElement.style.display = "none";

        // Camera controls.
        this.cameraFolder = gui.addFolder("Cameras");
        this.cameraFolder.domElement.style.display = "none";

        // Stats.
        // const perfFolder = gui.addFolder("Performance");
        // const perfLi = document.createElement("li");
        // this.stats.dom.style.position = "static";
        // perfLi.appendChild(this.stats.dom);
        // perfLi.classList.add("gui-stats");
        // perfFolder.__ul.appendChild(perfLi);

        const guiWrap = document.createElement("div");
        this.el.appendChild(guiWrap);
        guiWrap.classList.add(
            "gui-wrap",
            "absolute",
            "top-0",
            "right-0",
            "z-50",
        );
        guiWrap.appendChild(gui.domElement);
        gui.close();
    }

    updateGUI() {
        this.cameraFolder.domElement.style.display = "none";

        this.morphCtrls.forEach((ctrl) => ctrl.remove());
        this.morphCtrls.length = 0;
        this.morphFolder.domElement.style.display = "none";

        this.animCtrls.forEach((ctrl) => ctrl.remove());
        this.animCtrls.length = 0;
        this.animFolder.domElement.style.display = "none";

        const cameraNames = [];
        const morphMeshes = [];
        this.content.traverse((node) => {
            if (node.geometry && node.morphTargetInfluences) {
                morphMeshes.push(node);
            }
            if (node.isCamera) {
                node.name =
                    node.name || `VIEWER__camera_${cameraNames.length + 1}`;
                cameraNames.push(node.name);
            }
        });

        if (cameraNames.length) {
            this.cameraFolder.domElement.style.display = "";
            if (this.cameraCtrl) this.cameraCtrl.remove();
            const cameraOptions = [DEFAULT_CAMERA].concat(cameraNames);
            this.cameraCtrl = this.cameraFolder.add(
                this.state,
                "camera",
                cameraOptions,
            );
            this.cameraCtrl.onChange((name) => this.setCamera(name));
        }

        if (morphMeshes.length) {
            this.morphFolder.domElement.style.display = "";
            morphMeshes.forEach((mesh) => {
                if (mesh.morphTargetInfluences.length) {
                    const nameCtrl = this.morphFolder.add(
                        { name: mesh.name || "Untitled" },
                        "name",
                    );
                    this.morphCtrls.push(nameCtrl);
                }
                for (let i = 0; i < mesh.morphTargetInfluences.length; i++) {
                    const ctrl = this.morphFolder
                        .add(mesh.morphTargetInfluences, i, 0, 1, 0.01)
                        .listen();
                    Object.keys(mesh.morphTargetDictionary).forEach((key) => {
                        if (key && mesh.morphTargetDictionary[key] === i)
                            ctrl.name(key);
                    });
                    this.morphCtrls.push(ctrl);
                }
            });
        }

        if (this.clips.length) {
            this.animFolder.domElement.style.display = "";
            const actionStates = (this.state.actionStates = {});
            this.clips.forEach((clip, clipIndex) => {
                clip.name = `${clipIndex + 1}. ${clip.name}`;

                // Autoplay the first clip.
                let action;
                if (clipIndex === 0) {
                    actionStates[clip.name] = true;
                    action = this.mixer.clipAction(clip);
                    action.play();
                } else {
                    actionStates[clip.name] = false;
                }

                // Play other clips when enabled.
                const ctrl = this.animFolder
                    .add(actionStates, clip.name)
                    .listen();
                ctrl.onChange((playAnimation) => {
                    action = action || this.mixer.clipAction(clip);
                    action.setEffectiveTimeScale(1);
                    playAnimation ? action.play() : action.stop();
                });
                this.animCtrls.push(ctrl);
            });
        }
    }

    clear() {
        if (!this.content) return;

        this.scene.remove(this.content);

        // dispose geometry
        this.content.traverse((node) => {
            if (!node.geometry) return;

            node.geometry.dispose();
        });

        // dispose textures
        traverseMaterials(this.content, (material) => {
            for (const key in material) {
                if (
                    key !== "envMap" &&
                    material[key] &&
                    material[key].isTexture
                ) {
                    material[key].dispose();
                }
            }
        });
    }
}

function traverseMaterials(object, callback) {
    object.traverse((node) => {
        if (!node.geometry) return;
        const materials = Array.isArray(node.material)
            ? node.material
            : [node.material];
        materials.forEach(callback);
    });
}

// https://stackoverflow.com/a/9039885/1314762
function isIOS() {
    return (
        [
            "iPad Simulator",
            "iPhone Simulator",
            "iPod Simulator",
            "iPad",
            "iPhone",
            "iPod",
        ].includes(navigator.platform) ||
        // iPad on iOS 13 detection
        (navigator.userAgent.includes("Mac") && "ontouchend" in document)
    );
}
