import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

export default () => ({
    selectors: {
        linksElements: "[data-hp-link]",
        webglMap: "[data-webgl-map]",
    },
    inElements: null,
    activeLink: null,
    isLinkVisible: false,
    isMapVisible: false,

    init() {
        this.linksElements = Array.from(
            this.$el.querySelectorAll(this.selectors.linksElements),
        );

        this.webglElement = this.$el.querySelector(this.selectors.webglMap);

        this.$nextTick(() => {
            this.startScrollTrigger();
        });
    },

    setActiveLink(jsonObject) {
        this.isLinkVisible = true;
        this.activeLink = jsonObject;
    },

    clearActiveLink() {
        this.isLinkVisible = false;
    },

    startScrollTrigger() {
        ScrollTrigger.create({
            trigger: this.webglElement,
            start: "top bottom",
            end: "bottom top",
            scrub: 1,
            markers: false,
            onToggle: (self) => {
                this.isMapVisible = self.isActive;
            },
        });

        this.linksElements.forEach((el) => {
            const elData = el.getAttribute("data-hp-link"); // json decode;
            const jsonObject = JSON.parse(elData);

            // const decodedData = JSON.parse(elData);
            ScrollTrigger.create({
                trigger: el.parentElement,
                start: "top center",
                end: "bottom center",
                scrub: 1,
                markers: false,
                onToggle: (self) => {
                    if (self.isActive) {
                        this.setActiveLink(jsonObject);
                        console.log(jsonObject);
                    } else {
                        this.clearActiveLink();
                    }
                },
            });
        });
    },

    destroy() {},

    setScene() {},

    get transformWebgl() {
        return !this.isMapVisible && this.isLinkVisible;
    },

    get bigWebglVisible() {
        return this.isMapVisible;
    },

    get showActiveLink() {
        // return !this.isMapVisible && this.isLinkVisible;
        return this.isLinkVisible;
    },
});
