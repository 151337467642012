import "./bootstrap.js";
import "./swup.js";

import Alpine from "alpinejs";
import ui from "@alpinejs/ui";
import focus from "@alpinejs/focus";
import collapse from "@alpinejs/collapse";
import intersect from "@alpinejs/intersect";

import tabs from "./components/tabs.js";
import menu from "./components/menu.js";
import storyLong from "./components/storyLong.js";
import pageHeader from "./components/pageHeader.js";
import eventSlider from "./components/eventSlider.js";
import videoPlayer from "./components/videoPlayer.js";
import newVideoPlayer from "./components/newVideoPlayer.js";
import masonryGrid from "./components/masonryGrid.js";
import findOutMore from "./components/findOutMore.js";
import pageBuilder from "./components/pageBuilder.js";
import cookieConsent from "./components/cookieConsent.js";
import homePageSlider from "./components/homePageSlider.js";
import pageHeaderSlider from "./components/pageHeaderSlider.js";
import hpSlider from "./components/hpSlider.js";
import pagebuilderSlider from "./components/pagebuilderSlider.js";
import photoswipeGallery from "./components/photoswipeGallery.js";
import interview from "./components/interview.js";
import webgl from "./components/webgl.js";
import homePageBuilder from "./components/homePageBuilder.js";

import breakpoint from "./stores/breakpoint.js";

window.Alpine = Alpine;
Alpine.plugin(ui);
Alpine.plugin(focus);
Alpine.plugin(collapse);
Alpine.plugin(intersect);

document.addEventListener("alpine:init", () => {
    Alpine.data("tabs", tabs);
    Alpine.data("menu", menu);
    Alpine.data("storyLong", storyLong);
    Alpine.data("pageHeader", pageHeader);
    Alpine.data("eventSlider", eventSlider);
    Alpine.data("masonryGrid", masonryGrid);
    Alpine.data("findOutMore", findOutMore);
    Alpine.data("pageBuilder", pageBuilder);
    Alpine.data("videoPlayer", videoPlayer);
    Alpine.data("newVideoPlayer", newVideoPlayer);
    Alpine.data("cookieConsent", cookieConsent);
    Alpine.data("homePageSlider", homePageSlider);
    Alpine.data("pageHeaderSlider", pageHeaderSlider);
    Alpine.data("hpSlider", hpSlider);
    Alpine.data("pagebuilderSlider", pagebuilderSlider);
    Alpine.data("photoswipeGallery", photoswipeGallery);
    Alpine.data("interview", interview);
    Alpine.data("webgl", webgl);
    Alpine.data("homePageBuilder", homePageBuilder);

    Alpine.store("breakpoint", breakpoint);
});

Alpine.start();
